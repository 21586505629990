var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',[_c('b-link',[_c('b-avatar',{ref:"previewEl",staticClass:" text-secondary avatar-border-2",staticStyle:{"border-radius":"16px !important"},attrs:{"size":"80","square":"","src":_vm.avatar,"variant":"transparent"}},[(!_vm.avatar)?_c('u-icon',{attrs:{"icon":"fi-rr-user","size":"22"}}):_vm._e()],1)],1)],1),_c('b-media-body',{staticClass:"mt-75 ml-75"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75 mr-75",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){return _vm.$refs.refInputEl.$el.click()}}},[_vm._v(" Upload ")]),_c('b-form-file',{ref:"refInputEl",attrs:{"accept":".jpg, .png, .gif","hidden":true,"plain":""},on:{"input":_vm.inputImageRenderer},model:{value:(_vm.profileFile),callback:function ($$v) {_vm.profileFile=$$v},expression:"profileFile"}}),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-75 mr-75",attrs:{"variant":"outline-secondary","size":"sm"}},[_vm._v(" Reset ")]),_c('b-card-text',{staticClass:"text-secondary font-small-3"},[_vm._v("Allowed JPG, GIF or PNG. Max size of 800kB")])],1)],1),_c('validation-observer',{ref:"generalForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.saveGeneralData($event)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"name","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Full Name"),_c('label',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"name":"name","placeholder":"Name"},model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=$$v},expression:"fullName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Work E-mail Address"),_c('label',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"name":"email","placeholder":"xyz@companyname.com"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"phone","vid":"phone","rules":"required|numeric|min:10|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Mobile Number"),_c('label',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-input-group',{attrs:{"prepend":"+91"}},[_c('b-form-input',{attrs:{"name":"phone","placeholder":"9876543210","disabled":""},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"designation","vid":"designation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Designation"),_c('label',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"name":"designation","placeholder":"Sr. HR Manager"},model:{value:(_vm.designation),callback:function ($$v) {_vm.designation=$$v},expression:"designation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"primary","type":"submit","disabled":invalid}},[_vm._v(" Save changes ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_vm._v(" Reset ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }