<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <account-setting-general
        :general-data="recData"
      />
    </b-tab>
    <!--/ general tab -->

    

    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold"> Company Information</span>
      </template>

      <account-setting-information :recruiter-code="recData.RecruiterCode"
      />
    </b-tab>

    <!-- social links -->
    <!-- <b-tab>

      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Social</span>
      </template>

      <account-setting-social
        v-if="options.social"
        :social-data="options.social"
      />
    </b-tab> -->

    <!-- notification -->
    <!-- <b-tab>

      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Notifications</span>
      </template>

      <account-setting-notification
        v-if="options.notification"
        :notification-data="options.notification"
      />
    </b-tab> -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
import AccountSettingSocial from './AccountSettingSocial.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'
import firebase from "firebase/app";

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingInformation,
    AccountSettingSocial,
    AccountSettingNotification,
  },
  data() {
    return {
      options: {},
      recData:{}
    }
  },
  methods:{
    getRecruiterData(){
          var user = firebase.auth().currentUser
          firebase.firestore().collection("RecruiterId").doc(user.uid).get().then((doc) => {
            // console.log(doc.data())
            this.recData = doc.data()
          })
    }
  },
  created(){
    var user = firebase.auth().currentUser
    this.getRecruiterData()
    
  },
  // beforeCreate() {
  //   this.$http.get('/account-setting/data').then(res => { this.options = res.data })
  // },
}
</script>
