var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-alert',{attrs:{"show":"","variant":"primary"}},[_c('div',{staticClass:"alert-body d-flex align-middle items-center justify-content-center"},[_c('div',{staticClass:"pt-25"},[_c('u-icon',{staticClass:"mr-25",attrs:{"icon":"fi-rr-exclamation"}})],1),_c('small',{staticClass:" font-small-4 ml-25"},[_vm._v("Introduce yourself to job seekers. Information you might want to include: when and why your business was founded, how much you've grown since, what your company does and the types of customers you serve, and your mission statement. ")])])]),_c('validation-observer',{ref:"companyInfoForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.saveCompanyInfo($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"company name","vid":"company-name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Company Name"),_c('label',{staticClass:"text-danger"},[_vm._v("*")])]),_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredCompanies,"input-props":_vm.inputProps,"limit":10},on:{"selected":_vm.onSelected,"input":_vm.onInputChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('b-avatar',{staticClass:" text-secondary avatar-border-2",staticStyle:{"border-radius":"16px !important"},attrs:{"src":suggestion.item.logo,"square":"","variant":"transparent","text":_vm.avatarText(suggestion.item.name)}}),_c('div',{staticClass:"detail ml-50"},[_c('b-card-text',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(suggestion.item.name)+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(suggestion.item.CompanyWebsite)+" ")])],1)],1)]}}],null,true),model:{value:(_vm.companyName),callback:function ($$v) {_vm.companyName=$$v},expression:"companyName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"company description","vid":"company-description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Company Description"),_c('label',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-textarea',{attrs:{"rows":"4","placeholder":"About your company here...","name":"company description"},model:{value:(_vm.companyDesc),callback:function ($$v) {_vm.companyDesc=$$v},expression:"companyDesc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"company location","vid":"company-location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Company Headquarters"),_c('label',{staticClass:"text-danger"},[_vm._v("*")])]),_c('v-select',{attrs:{"name":"company location","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.locationOptions},model:{value:(_vm.companylocation),callback:function ($$v) {_vm.companylocation=$$v},expression:"companylocation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"company sector","vid":"company-sector","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Company Sector"),_c('label',{staticClass:"text-danger"},[_vm._v("*")])]),_c('v-select',{attrs:{"name":"company sector","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.companysectorList},model:{value:(_vm.companySector),callback:function ($$v) {_vm.companySector=$$v},expression:"companySector"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"company type","vid":"company-type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Company Type"),_c('label',{staticClass:"text-danger"},[_vm._v("*")])]),_c('v-select',{attrs:{"name":"company type","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.companytypeList},model:{value:(_vm.companyType),callback:function ($$v) {_vm.companyType=$$v},expression:"companyType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"company size","vid":"company-size","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Company Size"),_c('label',{staticClass:"text-danger"},[_vm._v("*")])]),_c('v-select',{attrs:{"name":"company size","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.sizeList},model:{value:(_vm.companySize),callback:function ($$v) {_vm.companySize=$$v},expression:"companySize"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"company website","vid":"company-website","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(" Company Website"),_c('label',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-input',{attrs:{"name":"company website","placeholder":"Website address"},model:{value:(_vm.companyWeb),callback:function ($$v) {_vm.companyWeb=$$v},expression:"companyWeb"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"glassdoor link","vid":"glassdoor-link","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"mb-50"},[_vm._v(" Glassdoor Link "),_c('label')]),_c('b-form-input',{attrs:{"name":"glassdoor link","placeholder":"https://glassdoor.co.in"},model:{value:(_vm.glassdoor),callback:function ($$v) {_vm.glassdoor=$$v},expression:"glassdoor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1 mr-1",attrs:{"variant":"primary","type":"submit","disabled":invalid}},[_vm._v(" Save changes ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-1",attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_vm._v(" Reset ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }