<template>
  <b-card>
    <!-- form -->
    <b-alert show variant="primary">
      <div class="alert-body d-flex align-middle items-center justify-content-center">
        <div class="pt-25">
          <u-icon class="mr-25" icon="fi-rr-exclamation" />
        </div>
        <small class=" font-small-4 ml-25">Introduce yourself to job seekers. Information you might want to include:
          when and why your business was founded, how much you've grown since, what your company does and the types of
          customers you serve, and your mission statement.
        </small>
      </div>
    </b-alert>

    <validation-observer ref="companyInfoForm" #default="{ invalid }">
      <b-form class="mt-2" @submit.prevent="saveCompanyInfo">
        <b-row>
          <!-- Select Company -->
          <b-col cols="12">
            <b-form-group>
              <validation-provider #default="{ errors }" name="company name" vid="company-name" rules="required">

                <label> Company Name<label class="text-danger">*</label></label>
                <vue-autosuggest :suggestions="filteredCompanies" :input-props="inputProps" @selected="onSelected"
                  :limit="10" @input="onInputChange" v-model="companyName">
                  <template slot-scope="{suggestion}">
                    <div class="d-flex align-items-center">
                      <b-avatar :src="suggestion.item.logo" square variant="transparent"
                        :text="avatarText(suggestion.item.name)" class=" text-secondary avatar-border-2"
                        style="border-radius: 16px !important;" />
                      <div class="detail ml-50">
                        <b-card-text class="mb-0">
                          {{ suggestion.item.name }}
                        </b-card-text>
                        <small class="text-muted">
                          {{ suggestion.item.CompanyWebsite }}
                        </small>
                      </div>
                    </div>
                  </template>


                </vue-autosuggest>

                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ select Company -->
          <!-- bio -->
          <b-col cols="12">
            <b-form-group>
              <validation-provider #default="{ errors }" name="company description" vid="company-description"
                rules="required">

                <label> Company Description<label class="text-danger">*</label></label>

                <b-form-textarea v-model="companyDesc" rows="4" placeholder="About your company here..."
                  name="company description" />
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ bio -->

          <!-- Location -->
          <b-col md="6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="company location" vid="company-location"
                rules="required">

                <label> Company Headquarters<label class="text-danger">*</label></label>
                <v-select v-model="companylocation" name="company location"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title" :options="locationOptions" />
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ Location  -->

          <!-- Sector -->
          <b-col md="6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="company sector" vid="company-sector" rules="required">

                <label> Company Sector<label class="text-danger">*</label></label>
                <v-select v-model="companySector" name="company sector"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title" :options="companysectorList" />
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ Sector -->

          <!-- Type -->
          <b-col md="6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="company type" vid="company-type" rules="required">

                <label> Company Type<label class="text-danger">*</label></label>
                <v-select v-model="companyType" name="company type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title" :options="companytypeList" />
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ Type -->

          <!-- Size -->
          <b-col md="6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="company size" vid="company-size" rules="required">

                <label> Company Size<label class="text-danger">*</label></label>
                <v-select v-model="companySize" name="company size" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title" :options="sizeList" />
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ Size -->

          <!-- website -->
          <b-col md="6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="company website" vid="company-website" rules="required">

                <label> Company Website<label class="text-danger">*</label></label>
                <b-form-input name="company website" v-model="companyWeb" placeholder="Website address" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ website -->

          <!-- glassdoor -->
          <b-col md="6">
            <b-form-group>
              <validation-provider #default="{ errors }" name="glassdoor link" vid="glassdoor-link" rules="">
                <label class="mb-50"> Glassdoor Link <label> </label></label>
                <b-form-input name="glassdoor link" v-model="glassdoor" placeholder="https://glassdoor.co.in" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- glassdoor -->

          <b-col cols="12">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1" type="submit"
              :disabled="invalid">
              Save changes
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" class="mt-1" variant="outline-secondary"
              @click.prevent="resetForm">
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BAvatar, BFormInput, BInputGroup, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BFormTextarea

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import { VueAutosuggest } from 'vue-autosuggest'
import { avatarText } from "@core/utils/filter";
import cityOptions from '../cityOptions'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import firebase from "firebase/app";
import "firebase/storage";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, numeric, min, max } from '@validations'
import store from "@/store";
export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BInputGroup,
    ToastificationContent,
    ValidationObserver,
    ValidationProvider,
    BAvatar,
    BFormTextarea,
    vSelect,
    flatPickr,
    Cleave,
    VueAutosuggest
  },
  directives: {
    Ripple,
  },
  props: {
    // informationData: {
    //   type: Object,
    //   default: () => { },
    // },
    recruiterCode: {
      type: String
    },
  },
  data() {
    return {
      countryOption: ['USA', 'India', 'Canada'],
      // localOptions: JSON.parse(JSON.stringify(this.informationData)),
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
      companyName: '',
      companyDesc: '',
      companySector: '',
      companylocation: '',
      companySize: '',
      companyType: '',
      companyWeb: '',
      glassdoor: '',
      // recruiterCode: store.state.mainUserCodes.RecruiterCode,
      selectedRecruiterCode: '',
      selectedCompanyObject: {},
      locationOptions: cityOptions,
      companysectorList: [
        "Analytics",
        "Consulting",
        "Computer Science - Software - IT",
        "E-Commerce",
        "Engineering & Technology",
        "Finance/ BFSI",
        "FMCG",
        "Healthcare",
        "Media/Entertainment",
        "Research & Development",
        "Telecom",
        "Energy",
        "Manufacturing & Technology",
        "Others"
      ],
      sizeList: ["Below 10", "10-50", "50-100", "100-500", "Above 500"],
      companytypeList: [
        "Early Stage",
        "Established Startup",
        "Publicly Traded",
        "Unicorn",
        "Private Company"
      ],
      required,
      email,
      numeric,
      min,
      max,
      recruiterList: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: "Enter your Company Name",
        name: "company name"
      },
      limit: 10,
      filteredCompanies: [],
      rCodesList: []


    }
  },
  created() {
    const thisIns = this
    firebase.firestore().collection("RecruiterCode").onSnapshot(function (snapshot) {
      // console.log(snapshot.size)
      snapshot.forEach(doc => {
        var obj = doc.data()
        obj.name = doc.data().CompanyName
        obj.code = doc.id
        thisIns.rCodesList.push(obj.code)
        thisIns.recruiterList.push(obj)

      })
    })
    // console.log(store.state.mainUserCodes)

  },
  watch: {
    recruiterCode() {
      this.getFirebaseData()
    }
  },
  methods: {
    getFirebaseData() {
      const thisIns = this
      if (thisIns.recruiterCode) {
        // console.log(thisIns.recruiterCode)
        firebase.firestore().collection("RecruiterCode").doc(thisIns.recruiterCode).get().then((doc) => {
          var obj = doc.data()
          obj.name = doc.data().CompanyName
          obj.code = doc.id
          thisIns.selectedCompanyObject = obj
          thisIns.selectedRecruiterCode = obj.code
          thisIns.setData()
        })
      }
    },
    setData() {
      this.companyName = this.selectedCompanyObject.name
      this.companyDesc = this.selectedCompanyObject.CompanyDescription
      this.companylocation = this.selectedCompanyObject.CompanyLocation
      this.companyWeb = this.selectedCompanyObject.CompanyWebsite
      this.companySector = this.selectedCompanyObject.CompanySector
      this.companyType = this.selectedCompanyObject.CompanyType
      this.companySize = this.selectedCompanyObject.CompanySize
      this.glassdoor = this.selectedCompanyObject.glassdoor ?? ''
    },
    onSelected(option) {
      // console.log(option)
      this.selectedCompanyObject = option.item
      this.selectedRecruiterCode = this.selectedCompanyObject.code
      this.setData()
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        return
      }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */

      const filteredArr = this.recruiterList.filter(item => {
        if (item.name) {
          return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1
        }
      }).slice(0, this.limit)
      this.filteredCompanies = [{
        data: filteredArr,
      }]
    },

    resetForm() {
      // this.localOptions = JSON.parse(JSON.stringify(this.informationData))
    },
    saveCompanyInfo() {
      this.$refs.companyInfoForm.validate().then(async success => {
        if (success) {
          var user = firebase.auth().currentUser;
          // console.log(`this is selected code ${this.selectedRecruiterCode}`)
          if (this.selectedRecruiterCode) {
            firebase.firestore().collection("RecruiterId").doc(user.uid).update({
              RecruiterCode: this.selectedRecruiterCode,
              companyDetails: true,
              CompanyName: this.companyName,
              CompanySector: this.companySector,
              CompanyLocation: this.companylocation,

            }).then(() => {
              firebase.firestore().collection("RecruiterCode").doc(this.selectedRecruiterCode).set({
                CompanyName: this.companyName,
                CompanyDescription: this.companyDesc,
                CompanyLocation: this.companylocation,
                CompanyWebsite: this.companyWeb,
                CompanySector: this.companySector,
                CompanyType: this.companyType,
                CompanySize: this.companySize,
                glassdoor: this.glassdoor,
              }, { merge: true }).then(() => {
                this.$store.dispatch("updateMainUserCodes", {
                  RecruiterCode: this.selectedRecruiterCode
                });
                // console.log('updated data')
                // }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successfully Updated',
                    icon: 'fi-rr-exclamation',
                    text: '👋 Company details are updated successfully.',
                    variant: 'success',
                  },
                })

              })
            }).catch((err) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Something went wrong!',
                  icon: 'fi-rr-exclamation',
                  text: `${err.message}`,
                  variant: 'danger',
                },
              })
            });
          } else {
            //register the code first

            let generatedCode = this.randomString(10, this.rCodesList);
            // console.log('we are generating new code')
            // console.log(generatedCode)
            firebase.firestore().collection("RecruiterId").doc(user.uid).update({
              RecruiterCode: generatedCode,
              companyDetails: true,
              CompanyName: this.companyName,
              CompanySector: this.companySector,
              CompanyLocation: this.companylocation,
            }).then(() => {
              firebase.firestore().collection("RecruiterCode").doc(generatedCode).set({
                CompanyName: this.companyName,
                CompanyDescription: this.companyDesc,
                CompanyLocation: this.companylocation,
                CompanyWebsite: this.companyWeb,
                CompanySector: this.companySector,
                CompanyType: this.companyType,
                CompanySize: this.companySize,
                glassdoor: this.glassdoor,
                timestamp: firebase.firestore.FieldValue.serverTimestamp()
              }, { merge: true }).then(() => {
                firebase
                  .firestore()
                  .collection("Codes")
                  .doc("Value")
                  .update({
                    RecruiterCode: firebase.firestore.FieldValue.arrayUnion(generatedCode)
                  });
                this.$store.dispatch("updateMainUserCodes", {
                  RecruiterCode: generatedCode
                });
                this.selectedRecruiterCode = generatedCode
                // console.log('updated data')
                // }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successfully Updated',
                    icon: 'fi-rr-exclamation',
                    text: '👋 Company details are updated successfully.',
                    variant: 'success',
                  },
                })

              })
            }).catch((err) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Something went wrong!',
                  icon: 'fi-rr-exclamation',
                  text: `${err.message}`,
                  variant: 'danger',
                },
              })
            });
          }
        }
      })
    },
    randomString(len, arr) {
      let charSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      var randomString = "";
      for (var i = 0; i < len; i++) {
        var randomPoz = Math.floor(Math.random() * charSet.length);
        randomString += charSet.substring(randomPoz, randomPoz + 1);
      }
      if (arr.includes(randomString)) return this.randomString(len, arr);
      else return randomString;
    },
    avatarText
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
